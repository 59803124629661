import React , {useState} from 'react';
import '../App.css';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
 
function Header() {
    const [name, setName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("name");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
    const user = {
        name:{name},
        email: 'admin@18pixels.com',
        imageUrl:
          'https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1671086646~exp=1671087246~hmac=ff76f8c87350ff2bd7508a6104c7f7c17d283beb1253154d257342cb98797090',
      }
      const location = useLocation();
      const { pathname } = location;
      const splitLocation = pathname.split("/");
      const navigation = [
        { name: 'Dashboard', href: './dashboard', current:splitLocation[1] === "dashboard" ? true : false },
        { name: 'Upload Doc', href: './upload',  current: splitLocation[1] === "upload" ? true : false } 
      ]
      const userNavigation = [
        { name: 'Sign out', href: '/' }
      ]

     const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        window.location.href = "/";
      };
   
  return (
    <>
       {/* {console.log(name)} */}

        <Disclosure as="nav" className="bg-gray-800">
          {/* {  console.log(name)} */}
        {({ open }) => (
            <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                    <img
                        className="h-12"
                        src="https://18pixels.com/img/18pixels.png"
                        alt="18pixels"
                    />
                    </div>
                    <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                            item.current
                                ? 'bg-gray-600 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >
                            {item.name}
                        </a>
                        ))}
                    </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                        <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                             
                        </Menu.Button>
                        </div>
                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                        >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                           <span className='block mb-2 text-gray-600'>{name}</span>
                            {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                                
                                <Link onClick={handleLogout}
                                  
                                >
                                    {item.name}
                                </Link>
                                
                                
                            </Menu.Item>
                            
                            ))}
                        </Menu.Items>
                        </Transition>
                    </Menu>
                    </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                    </Disclosure.Button>
                </div>
                </div>
            </div>

            <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map((item) => (
                    <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                    >
                    {item.name}
                    </Disclosure.Button>
                ))}
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                       
                    </div>
                    <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">{user.name}</div>
                        <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                    <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                        {item.name}
                    </Disclosure.Button>
                    ))}
                </div>
            </div>
            </Disclosure.Panel>
            </>
        )}
        </Disclosure>
          
    </>
  )
}

export default Header
