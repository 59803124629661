import Login from './components/Login';
import Dashboard from './components/Dashboard';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Upload from './components/Upload';


function App() {
  const token = localStorage.getItem('token');
  if(!token) {
    return <Login />
  }
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route exact path='/' element={< Login />}></Route>
            <Route  path='/dashboard' element={< Dashboard />}></Route>
            <Route  path='/upload' element={< Upload />}></Route>
          </Routes>
      </Router>
      
    </div>
  );
}

export default App;
