import React, {useState, useEffect} from 'react'
// import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';


async function loginUser(credentials) {
    return fetch('https://tm.eighteenpixels.com/taskapi-management/api/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

function Login() {
    // const [name, setName] = useState("");
    //const classes = useStyles();
 
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    // const navigate = useNavigate();
    const handleSubmit = async e => {
        e.preventDefault();
        if (username.trim().length === 0 || password.trim().length === 0) {
            swal("Failed", 'Login credentials are required.', "error", {
                button: true,
                 
            })
        }
        else{
              
                const response = await loginUser({
                username,
                password
                });
                if ('token' in response) {
                // swal("Success", response.message, "success", {
                //     buttons: false,
                //     tidmer: 2000,
                // })
                // .then((value) => {
                    localStorage.setItem('token', response['token']);
                    localStorage.setItem('name', JSON.stringify(username));
                    window.location.href = "/dashboard";
                    
                // });
                } else {
                    swal("Failed", 'Invalid Login credentials', "error", {
                      button: true,
                    });
                }
         }

         
        
    }

    useEffect(() => {
        // storing input name
        handleSubmit()
     }, [username]);
     
  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
        <div className="w-full mb-0 p-0 m-auto rounded-md  lg:max-w-xl text-center">
            <img className='logo m-auto' src='https://18pixels.com/img/18pixels.png' />
        </div>
         <div className="w-full login-box mt-10 p-6 m-auto rounded-md shadow-md lg:max-w-xl">
            <h1 className="text-3xl font-semibold text-center text-red-700  ">
            Sign in
            </h1>
            <form className="mt-6">
                <div className="mb-4 text-left">
                    <label
                        for="email"
                        className="block text-sm font-semibold text-gray-800" >
                        Email
                    </label>
                    <input
                        type="email" placeholder='Enter email' onChange={(e) => setUserName(e.target.value)}
                        className="block w-full px-4 py-2 mt-2 text-black-600 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2 text-left">
                    <label
                        for="password"
                        className="block text-sm font-semibold text-gray-800"   >
                        Password
                    </label>
                    <input
                        type="password"   onChange={(e) => setPassword(e.target.value)} placeholder='Enter password'
                        className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                {/* <p className='error text-red-400' >{error}</p>  */}
                <div className="mt-10">
                    <button type='button' onClick={handleSubmit} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                        Login
                    </button> 
                 
                    
                </div>
            </form>

             
        </div>
    </div>
  )
}

export default Login
