import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Header from './Header';

function Dashboard() {
    const [posts, setPosts] = useState([]);
    const [filteredData,setFilteredData] = useState(posts);
    
    useEffect(() => {
 
            axios.get('https://tm.eighteenpixels.com/file-management/file-app-list')
            .then(res =>{
                //console.log(res.data.data)
                setPosts(res.data.data)
                //console.log(posts)
            })
            .catch(err=>{
                console.log(err)
            })
       
    });

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString();
      }

      const columns = [
        {
            name:"Project",
            selector:(row)=>row.project,
            width:"100px"
        },
        {
            name:"Doc Title",
            selector:(row)=>row.file_title,
            
        },
        {
            name:"Description",
            selector:(row)=>row.description,
           
        },
        {
            name:"Doc Type",
            selector:(row)=>row.doc_type,
            width:"120px"
        },
        {
            name:"File",
            cell:row=><button className='text-xs inline-flex px-3 py-1  font-medium text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' onClick={()=>window.open(row.url_link)} >View File</button>,
            width:"120px"
        }
        ,
        {
            name:"Date",
            selector:row=>getFormattedDate(row.Date),
            width:"150px"
        }

      ]

     const handleSearch = (event) =>{
            const data = event.target.value;
            console.log(posts.filter(posts=>posts.file_title.toLowerCase().includes(filteredData)));
      }


  return (
     <>
            <Header />
            <header className="bg-white shadow">
                <div class="grid grid-cols-2 gap-3">
                    <div className= " max-w-7xl py-5 px-4 sm:px-6 lg:px-20">
                        <h1 className="text-2xl font-normal tracking-tight text-gray-600">Dashboard</h1>
                    </div>
                    <div className='text-right py-3 px-4 sm:px-6 lg:px-20'>
                       <input
                            type="email" placeholder='Search here...'  
                            onChange={(e)=>setFilteredData(e.target.value)}
                            className="block w-full px-4 py-2 mt-2 text-black-600 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                </div>
            </header>
                        
            <main className='dashboard min-h-full'>

                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                   
                    <div className="px-4 py-2 sm:px-0">
                        <div class="grid grid-cols-1  ">
                            <DataTable  
                                columns={columns}  
                                data={posts.filter(posts=>posts.file_title.toLowerCase().includes(filteredData))}
                                customStyles={tableCustomStyles}
                                pagination
                                />
                        </div>

                    </div>
                     
                </div>
            </main>
     </>
      
     
  )
}

const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: '#ccc'
      },
    },
  }

export default Dashboard
