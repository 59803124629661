import React, { useEffect, useState } from 'react'
import Header from './Header'
import axios from 'axios'
import swal from 'sweetalert'
// import Projects from './Projects'

function Upload() {
    const [document, setDocument] = useState([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState("");
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [url, setUrl] = useState("");
    const [desp, setDesp] = useState("");
    const [message, setMessage] = useState("");
 

    useEffect(() => {
            axios.get('https://tm.eighteenpixels.com/file-management/project-list')
            .then(res =>{
                
                setProjects(res.data.data)
            })
            .catch(err=>{
                console.log(err)
            })
    });
     

    let handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(project)
        if(project===""){
            setMessage("Please select project.");
         }
        else if (title.trim().length === 0) {
            setMessage("Please enter document title.");
        }
        
         else if(type===""){
            setMessage("Please select document type.");
         }
         else if(url.trim().length === 0){
            setMessage("Please enter file URL.");
         }
        else{
           
            const formdata = {
                project,
                title,
                type,
                url,
                desp
            }
            try {
            let res = await fetch("https://tm.eighteenpixels.com/file-management/file-apply", {
                method: "POST",
                headers:{
                    'Content-type':'application/json; chartset-utf-8'
                },
                body: JSON.stringify({
                    project:project,
                    file_title:title,
                    doc_type:type,
                    url_link:url,
                    description:desp
                }),
            });
            let resJson = await res.json();
            if (res.status === 200) {
                setTitle("");
                setUrl("");
                setDesp("");
                setProject("");
                setType("");
                // console.log(res.data);
                //setMessage("User created successfully");
                swal("Success", 'Document uploaded successfully..', "success", {
                    button: true,
                })
            } else {
                swal("Failed", 'Some error occured. Please try again.', "failed", {
                    button: true,
                })
            }
            } catch (err) {
            console.log(err);
            }
         }
       };

    
  return (
    <>
        <Header />
        <header className="bg-white shadow">
            <div className= " max-w-7xl py-5 px-4 sm:px-6 lg:px-20">
                <h1 className="text-2xl font-normal tracking-tight text-gray-600">Upload Docs</h1>
            </div>
        </header>
        <main className='dashboard min-h-full'>
                <div className="mx-auto  sm:px-6 lg:px-8">
                    <div className="px-4 py-0 sm:px-0">
                        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                            <div className="w-full login-box mt-10 p-6 m-auto rounded-md shadow-md lg:max-w-5xl">
                              <form className="mt-1" onSubmit={handleSubmit}>
                              
                                 <div class="grid grid-cols-2 gap-5  ">
                                   <div className="mb-2 text-left">
                                        <label
                                            for="email"
                                            className="block text-sm font-semibold text-gray-800" >
                                            Project<span className='text-red-500 ml-1'>*</span>
                                        </label>
                                        <select onChange={(e) => setProject(e.target.value)}
                                         value={project}  
                                         className="block w-full px-4 py-2 mt-2 text-black-600 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                            <option value={0} >Select</option>
                                            {projects.map(project => (
                                                <option value={project.project_Id}>{project.project_name}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                    <div className="mb-2 text-left">
                                        <label
                                            for="email"
                                            className="block text-sm font-semibold text-gray-800" >
                                            File Title<span className='text-red-500 ml-1'>*</span>
                                        </label>
                                        <input 
                                            type="text"
                                            onChange={(e) => setTitle(e.target.value)}
                                             placeholder='Enter Doc title'  value={title}
                                            className="block w-full px-4 py-2 mt-2 text-black-600 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        />
                                    </div>
                                    <div className="mb-2 text-left">
                                        <label
                                            for="email"
                                            className="block text-sm font-semibold text-gray-800" >
                                            Document Type<span className='text-red-500 ml-1'>*</span>
                                        </label>
                                        <select 
                                         onChange={(e) => setType(e.target.value)}
                                         value={type}
                                         className="block w-full px-4 py-2 mt-2 text-black-600 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                            <option value={0} >Select</option>
                                            <option value="Excel">Excel</option>
                                            <option value="PDF">PDF</option>
                                            <option value="Word Doc">Word Doc</option>
                                            <option value="Image">Image</option>       
                                        </select>
                                    </div>
                                    <div className="mb-2 text-left">
                                        <label
                                            for="text"
                                            className="block text-sm font-semibold text-gray-800"   >
                                            File URL<span className='text-red-500 ml-1'>*</span>
                                        </label>
                                        <input onChange={(e) => setUrl(e.target.value)} value={url}
                                            type="text"    placeholder='Enter password'
                                            className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                        />
                                    </div>
                                    <div className="mb-0  text-left col-span-full">
                                        <label
                                            for="text"
                                            className="block text-sm font-semibold text-gray-800"   >
                                            File Description
                                        </label>
                                        <textarea  value={desp} onChange={(e) => setDesp(e.target.value)} placeholder='Enter password'
                                            className="block w-full px-4 py-2 mt-2 text-black-700 bg-white border border-gray-400 rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                                        </textarea>
                                        
                                    </div>
                                    {/* <p className='error text-red-400' >{error}</p>  */}
                                    <div className="message text-red-600 mt-0 ">{message ? <p>{message}</p> : null}</div>
                                    <div className="mt-2  col-span-full text-center">
                                        
                                        <button type='submit'  className="px-14 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                                            Submit 
                                        </button> 
                                    
                                        
                                    </div>
                                   
                                    
                                  </div>
                                </form>

                                
                            </div>
                        
                        </div>
                     </div>
                 </div>
         </main>

    </>
  )
}

export default Upload
